import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RequestQuotesDialogComponent } from './request-quotes-dialog';
import { RequestQuotesButtonComponent } from './request-quotes-button';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [RequestQuotesDialogComponent, RequestQuotesButtonComponent],
  imports: [CommonModule, MatButtonModule, MatDialogModule, MatInputModule, MatFormFieldModule, FormsModule, ReactiveFormsModule],
  exports: [RequestQuotesDialogComponent, RequestQuotesButtonComponent],
})
export class RequestQuotesModule {}
