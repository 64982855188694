import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'common-ui';
import { firstValueFrom } from 'rxjs';
import { QuotesService } from '../../quotes';

const maxAttachmentTotalSize = 47185920; // 45MB
const maxAttachmentFileSize = 47185920; // 45MB
const validFileTypes = [
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.csv',
  '.txt',
  '.rtf',
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.svg',
  '.bmp',
  '.tiff',
  '.tif',
];

@Component({
  selector: 'request-quotes-dialog',
  templateUrl: './request-quotes-dialog.component.html',
  styleUrls: ['./request-quotes-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestQuotesDialogComponent implements OnInit {
  public loading = false;
  public readonly validFileTypes = validFileTypes;
  public errors: string[] = [];
  public form = new FormGroup({
    message: new FormControl('', Validators.required),
    attachments: new FormControl<FileList | null>(null, (control) => {
      const files = Array.from((control.value as FileList) ?? []);
      let err: ValidationErrors | null = null;
      const fileTotal = files.reduce((acc, file) => {
        if (validFileTypes.includes(file.name.slice(file.name.lastIndexOf('.')).toLowerCase()) === false) {
          err ??= {};
          err['fileType'] ??= [];
          err['fileType'].push(file);
          this.errors.push(`File ${file.name} is not a valid file type.`);
        }
        if (file.size > maxAttachmentFileSize) {
          err ??= {};
          err['fileSize'] ??= [];
          err['fileSize'].push(file);
          this.errors.push(`File ${file.name} is too large. Max file size is 45MB.`);
        }
        return acc + file.size;
      }, 0);

      if (fileTotal > maxAttachmentTotalSize) {
        err ??= {};
        err['totalSize'] = true;
        this.errors.push(`Total file size exceeds 45MB.`);
      }
      return err;
    }),
  });

  constructor(
    private readonly quoteService: QuotesService,
    private readonly changeRef: ChangeDetectorRef,
    private readonly dialogRef: MatDialogRef<RequestQuotesDialogComponent>,
    private readonly dialogService: DialogService,
  ) {}

  ngOnInit(): void {}

  onFileSelected(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    this.errors = [];
    this.form.patchValue({ attachments: files });
  }

  async onSubmit() {
    this.loading = true;
    this.changeRef.markForCheck();
    this.errors = [];
    try {
      await firstValueFrom(this.quoteService.requestQuote(this.form.value.message!, this.form.value.attachments!));
      this.dialogRef.close();
      this.dialogService.message('Quote request sent successfully.');
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        const err = error.error;
        if (Array.isArray(err)) {
          this.errors = err.map((e) => e.errorMessage);
        }
      }
      console.error(error);
    } finally {
      this.loading = false;
      this.changeRef.markForCheck();
    }
  }
}
