import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RequestQuotesDialogComponent } from '../request-quotes-dialog';

@Component({
  selector: 'request-quotes-button',
  templateUrl: './request-quotes-button.component.html',
  styleUrls: ['./request-quotes-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestQuotesButtonComponent implements OnInit {
  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  openDialog() {
    this.dialog.open(RequestQuotesDialogComponent, {
      maxWidth: 'calc(100vw - 32px)',
    });
  }
}
