import { QuoteSearchListItem } from '@idealsupply/ngclient-webservice-quotes';
import { IPagedResult, PagedDataSource } from 'lib-products';
import { firstValueFrom } from 'rxjs';
import { QuotesService } from './quotes.service';

export interface IQuoteListFilter {
  lineCode?: string;
  partNumber?: string;
  customerNumber?: string;
  quoteNumber?: number;
  customerPo?: string;
  active?: boolean;
}

export class QuoteListDataSource extends PagedDataSource<QuoteSearchListItem> {
  private _filter: IQuoteListFilter = {};

  constructor(
    private readonly quotesService: QuotesService,
    skip: number = 0,
    limit: number = -1,
  ) {
    super(skip, limit);
  }

  private cleanFilter(data: IQuoteListFilter): IQuoteListFilter {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const val = (data as any)[key];
        if (val === '' || val === null || val === undefined || Number.isNaN(val)) {
          delete (data as any)[key];
        }
      }
    }
    return data;
  }

  private _compareFilter(a?: IQuoteListFilter, b?: IQuoteListFilter): boolean {
    return JSON.stringify(this.cleanFilter(a ?? {})) === JSON.stringify(this.cleanFilter(b ?? {}));
  }

  private _isFilterEmpty(a?: IQuoteListFilter): boolean {
    return this._compareFilter(a, undefined);
  }

  protected update(): Promise<IPagedResult<QuoteSearchListItem>> {
    const f = this._filter ?? {};
    this.cleanFilter(f);

    return firstValueFrom(
      this.quotesService.search(
        this.customerNumber,
        this.customerPo,
        this.quoteNumber,
        this.lineCode,
        this.partNumber,
        this.active,
        this.skip,
        this.limit,
      ),
    );
  }

  public get filter(): IQuoteListFilter {
    return this._filter;
  }

  public set filter(value: IQuoteListFilter) {
    value ??= {};
    if (JSON.stringify(this.filter) !== JSON.stringify(value)) {
      this.changed = true;
      this._filter = value;
      this.invalidateResults();
    }
  }

  public override initialize(value?: IQuoteListFilter): Promise<this> {
    this._filter = value ?? {};
    return super.initialize();
  }

  public get customerNumber(): string | undefined {
    return this._filter.customerNumber;
  }

  public set customerNumber(value: string | undefined) {
    value = value || undefined;
    if (this.customerNumber !== value) {
      this.changed = true;
      this._filter.customerNumber = value;
      this.invalidateResults();
    }
  }

  public get customerPo(): string | undefined {
    return this._filter.customerPo;
  }

  public set customerPo(value: string | undefined) {
    value = value || undefined;
    if (this.customerPo !== value) {
      this.changed = true;
      this._filter.customerPo = value;
      this.invalidateResults();
    }
  }

  public get quoteNumber(): number | undefined {
    return this._filter.quoteNumber;
  }

  public set quoteNumber(value: number | undefined) {
    value = value || undefined;
    if (this.quoteNumber !== value) {
      this.changed = true;
      this._filter.quoteNumber = value;
      this.invalidateResults();
    }
  }

  public get lineCode(): string | undefined {
    return this._filter.lineCode;
  }

  public set lineCode(value: string | undefined) {
    value = value || undefined;
    if (this.lineCode !== value) {
      this.changed = true;
      this._filter.lineCode = value;
      this.invalidateResults();
    }
  }
  public get partNumber(): string | undefined {
    return this._filter.partNumber;
  }

  public set partNumber(value: string | undefined) {
    value = value || undefined;
    if (this.partNumber !== value) {
      this.changed = true;
      this._filter.partNumber = value;
      this.invalidateResults();
    }
  }
  public get active(): boolean | undefined {
    return this._filter.active;
  }

  public set active(value: boolean | undefined) {
    value = value ?? undefined;
    if (typeof value === 'string') {
      if (value === 'true') {
        value = true;
      } else if (value === 'false') {
        value = false;
      } else {
        value = undefined;
      }
    }
    if (this.active !== value) {
      this.changed = true;
      this._filter.active = value;
      this.invalidateResults();
    }
  }
}
