import { Inject, Injectable, InjectionToken } from '@angular/core';
import { QuotesApi } from '@idealsupply/ngclient-webservice-quotes';
import { CustomerService } from 'lib-customer';
import { AuthenticationService } from 'authentication-data';
import { HttpClient, HttpHeaders } from '@angular/common/http';

export const REQUEST_QUOTE_BASE_PATH = new InjectionToken<string>('REQUEST_QUOTE_BASE_PATH', {
  factory: () => {
    return 'https://api.idealsupply.com/messaging/v0';
  },
});
export const REQUEST_QUOTE_MESSAGE_GROUP_ID = new InjectionToken<string>('REQUEST_QUOTE_MESSAGE_GROUP_ID');

@Injectable({
  providedIn: 'root',
})
export class QuotesService {
  constructor(
    private readonly quotesApi: QuotesApi,
    private readonly customerService: CustomerService,
    private readonly authenticationService: AuthenticationService,
    private readonly httpClient: HttpClient,
    @Inject(REQUEST_QUOTE_BASE_PATH) private readonly basePath: string,
    @Inject(REQUEST_QUOTE_MESSAGE_GROUP_ID) private readonly messageGroupId: string,
  ) {}

  public search(
    customerNumber?: string,
    customerPo?: string,
    quoteNumber?: number,
    lineCode?: string,
    partNumber?: string,
    active?: boolean,
    skip?: number,
    limit?: number,
  ) {
    return this.quotesApi.getQuotes(customerNumber, customerPo, quoteNumber, lineCode, partNumber, active, skip, limit);
  }

  public getQuote(quoteId: string) {
    return this.quotesApi.getQuoteById(quoteId);
  }

  public requestQuote(message: string, attachments: FileList) {
    const formData = new FormData();
    formData.append('message', message);
    formData.append('accountNumber', this.customerService.customerNumber?.customerNumber || '');
    if (attachments) {
      for (let i = 0; i < attachments.length; i++) {
        formData.append('attachments', attachments[i]);
      }
    }
    // let headers = new HttpHeaders();
    // headers = headers.set('Authorization', `Bearer ${this.authenticationService.}`);
    return this.httpClient.post<void>(`${this.basePath}/${this.messageGroupId}/request-quote`, formData, {
      // headers,
      withCredentials: true,
    });
  }
}
