<h1 mat-dialog-title>Quote Request</h1>
<form mat-dialog-content novalidate [formGroup]="form">
  <mat-form-field color="accent" appearance="outline">
    <mat-label>Request</mat-label>
    <textarea matInput placeholder="How can we help?" formControlName="message"></textarea>
  </mat-form-field>

  <mat-label> Upload FIle/Image </mat-label>
  <input type="file" (change)="onFileSelected($event)" [accept]="validFileTypes" multiple />
  <div *ngIf="errors.length" class="errors">
    <label>Errors:</label>
    <div *ngFor="let error of errors">
      {{ error }}
    </div>
  </div>
</form>
<mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close>Cancel</button>
  <button mat-flat-button [disabled]="form.invalid || loading" color="accent" (click)="onSubmit()">Send Request</button>
</mat-dialog-actions>
